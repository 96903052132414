import React from "react";
import conf from "./conf.json";

const daysBetween = (start: Date, end: Date) => {
  let difference = end.getTime() - start.getTime();
  let TotalDays = Math.floor(difference / 1000 / 3600 / 24);
  return TotalDays;
};

const computeCountdown = () => {
  const now = new Date();
  const difference =
    new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1).getTime() -
    now.getTime();

  var hours = Math.floor(
    (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  var minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((difference % (1000 * 60)) / 1000);

  return { hours, minutes, seconds };
};

function App() {
  let [countdown, setCountdown] = React.useState(computeCountdown());
  let [currentImage, setCurrentImage] = React.useState<string>("");

  React.useEffect(() => {
    let images = conf.uris;
    let start = new Date(conf.start);
    let today = new Date();

    let imageOffset = daysBetween(start, today);
    setCurrentImage(images[imageOffset % images.length]);

    let interval = setInterval(() => setCountdown(computeCountdown()), 1000);
    return () => clearInterval(interval);
  }, []);

  const outer = `
    h-screen w-screen
    bg-[color:var(--background-color)]
    bg-[image:var(--current-image)] bg-cover bg-center
  `;

  const inner = `
    h-screen w-screen flex flex-col justify-around items-center
    backdrop-blur-[147px]
  `;

  const theBeard = `max-h-[50vh] max-w-[90vw] w-auto h-auto rounded-md`;

  const caption = `press-start text-white drop-shadow-lg text-center pt-3`;
  const timer = `press-start text-white drop-shadow-lg text-center pt-0.5 text-xs`;

  const styles = {
    "--current-image": `url(${currentImage})`,
    "--background-color": "#110505",
  } as React.CSSProperties;

  return (
    <div className={outer} style={styles}>
      <div className={inner}>
        <div>
          <img src={currentImage} alt="The Beard" className={theBeard} />
          <div className={caption}>But is it art, sweetie?</div>
          <div className={timer}>
            Next image in: {countdown.hours}:{countdown.minutes}:
            {countdown.seconds}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
